import React, { useState, useEffect } from 'react';
import { 
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend,
  Sector
} from 'recharts';
import { Clock, PlayCircle, CheckCircle, Users } from 'lucide-react';
import styles from '../../styles/UniversityQuizAppStyles/StatisticsDashboard.module.css';
import { fetchQuizStatistics, fetchAnswerStatistics } from '../../utils/api';
import { useTranslation } from 'react-i18next';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];

const StatisticsDashboard = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statsData, setStatsData] = useState({
    test_started: 0,
    total_responses: 0,
    avg_completion_time: { minutes: 0, seconds: 0 },
    profile_statistics: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [quizStats, answerStats] = await Promise.all([
          fetchQuizStatistics(i18n.language),
          fetchAnswerStatistics(i18n.language)
        ]);

        setStatsData({
          ...quizStats.data.summary,
          profile_statistics: answerStats.data.profile_statistics || []
        });
        
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError(t('statisticsDashboard.loadError'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [i18n.language, t]);

  const SummaryCard = ({ icon: Icon, title, value, unit = '', description = '' }) => (
    <div className={styles.summaryCard}>
      <div className={styles.cardHeader}>
        <Icon size={20} className={styles.cardIcon} />
        <span className={styles.cardTitle}>{title}</span>
      </div>
      <div className={styles.cardValue}>
        {typeof value === 'object' && value.minutes !== undefined ? (
          `${value.minutes} ${t('statisticsDashboard.time.minutes')} ${value.seconds} ${t('statisticsDashboard.time.seconds')}`
        ) : (
          <>
            {value}
            {unit && <span className={styles.cardUnit}>{unit}</span>}
          </>
        )}
      </div>
      {description && <p className={styles.cardDescription}>{description}</p>}
    </div>
  );

  const ProfileDistribution = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const renderActiveShape = (props) => {
      const {
        cx, cy, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent
      } = props;

      return (
        <g>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius + 6}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <text
            x={cx}
            y={cy - 8}
            textAnchor="middle"
            fill="#fff"
            fontSize={14}
          >
            {payload.name}
          </text>
          <text
            x={cx}
            y={cy + 8}
            textAnchor="middle"
            fill="#fff"
            fontSize={14}
          >
            {`${(percent * 100).toFixed(1)}%`}
          </text>
        </g>
      );
    };

    const chartData = data.map(item => ({
      name: item.profile_name,
      value: item.count,
      percentage: item.percentage
    }));

    return (
      <div className={styles.distributionCard}>
        <h3 className={styles.distributionTitle}>
          {t('statisticsDashboard.profileDistribution')}
        </h3>
        <div className={styles.chartAndResponses}>
          <div className={styles.chartWrapper}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={(_, index) => setActiveIndex(index)}
                  onMouseLeave={() => setActiveIndex(null)}
                >
                  {chartData.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className={styles.tooltip}>
                          <p>
                            {`${payload[0].name}: ${payload[0].value} (${payload[0].payload.percentage}%)`}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.responsesWrapper}>
            <div className={styles.answerList}>
              {chartData.map((item, index) => (
                <div key={index} className={styles.answerItem}>
                  <div
                    className={styles.answerColorIndicator}
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <div className={styles.answerInfo}>
                    <div className={styles.answerText}>{item.name}</div>
                    <div className={styles.answerCount}>
                      {item.value} ({item.percentage}%)
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className={styles.loading}>{t('statisticsDashboard.loading')}</div>;
  }

  if (error) {
    return (
      <div className={styles.error}>
        {error}
        <button onClick={() => window.location.reload()} className={styles.retryButton}>
          {t('statisticsDashboard.retry')}
        </button>
      </div>
    );
  }

  const mostCommonProfile = statsData.profile_statistics[0] || null;

  return (
    <div className={styles.statisticsDashboard}>
      <div className={styles.summaryGrid}>
        <SummaryCard
          icon={PlayCircle}
          title={t('statisticsDashboard.testStarted')}
          value={statsData.test_started}
        />
        <SummaryCard
          icon={CheckCircle}
          title={t('statisticsDashboard.totalResponses')}
          value={statsData.total_responses}
        />
        <SummaryCard
          icon={Clock}
          title={t('statisticsDashboard.avgCompletionTime')}
          value={statsData.avg_completion_time}
        />
        <SummaryCard
          icon={Users}
          title={t('statisticsDashboard.mostCommonProfile')}
          value={mostCommonProfile ? mostCommonProfile.profile_name : '-'}
          description={mostCommonProfile ? t('statisticsDashboard.matchRate', { 
            percentage: mostCommonProfile.percentage.toFixed(1) 
          }) : ''}
        />
      </div>

      {statsData.profile_statistics.length > 0 && (
        <ProfileDistribution data={statsData.profile_statistics} />
      )}
    </div>
  );
};

export default StatisticsDashboard;