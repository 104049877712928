import React from 'react';
import { motion } from 'framer-motion';
import styles from '../../styles/UniversityQuizAppStyles/LoadingComponent.module.css';

const LoadingComponent = ({ primaryColor = '#FAFAFA', secondaryColor = '#4E529A' }) => {
  return (
    <div className={styles.container} style={{ backgroundColor: primaryColor }}>
      <motion.div
        className={styles.spinner}
        style={{
          border: `4px solid ${secondaryColor}`,
          borderTop: '4px solid transparent',
        }}
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
      />
      <p className={styles.loadingText} style={{ color: secondaryColor }}>
        Loading...
      </p>
      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
          Powered by WorkXplorer
          <img 
            src="/logo.png" 
            alt="WorkXplorer" 
            className={styles.workxplorerIcon}
          />
        </div>
      </footer>
    </div>
  );
};

export default LoadingComponent;