import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import QuizWrapper from './components/UniversityQuizApp/QuizWrapper';
import AdminDashboard from './components/UniversityQuizApp/AdminDashboard';
import Login from './components/UniversityQuizApp/Login';
import { getSubdomain } from './utils/helpers';
import styles from './App.module.css';

function App() {
  const subdomain = getSubdomain();

    return (
      
        <Router>
          <div className={styles.app}>
            <Routes>
          {!subdomain && (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
          {subdomain && (
            <>
              <Route path="/" element={<QuizWrapper />} />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
        </div>
      </Router>
    
  );
}

export default App;