import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
} from 'recharts';
import styles from '../../styles/UniversityQuizAppStyles/ResultPage.module.css';

const ResponsiveRadarChart = ({ data, secondaryColor }) => {
  const [dimensions, setDimensions] = useState({
    outerRadius: 110,
    labelRadius: 120,
    margin: { top: 70, right: 70, bottom: 70, left: 70 }
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setDimensions({
          outerRadius: 60,
          labelRadius: 70,
          margin: { top: 40, right: 40, bottom: 40, left: 40 }
        });
      } else if (width <= 768) {
        setDimensions({
          outerRadius: 90,
          labelRadius: 100,
          margin: { top: 50, right: 50, bottom: 50, left: 50 }
        });
      } else {
        setDimensions({
          outerRadius: 110,
          labelRadius: 120,
          margin: { top: 70, right: 70, bottom: 70, left: 70 }
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CustomizedAxisTick = ({ payload, cx, cy }) => {
    const angleStep = 360 / data.length;
    const angle = payload.index * angleStep;
    const radian = (Math.PI / 180) * angle;
  
    const xPos = cx + dimensions.labelRadius * Math.cos(radian - Math.PI / 2);
    const yPos = cy + dimensions.labelRadius * Math.sin(radian - Math.PI / 2);
  
    const maxCharsPerLine = window.innerWidth <= 480 ? 8 : 12;
    const words = payload.value.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach(word => {
      if (currentLine.length + word.length <= maxCharsPerLine) {
        currentLine += (currentLine.length ? ' ' : '') + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });
    if (currentLine) {
      lines.push(currentLine);
    }

    let textAnchor = 'middle';
    if (angle < 10 || angle > 350) textAnchor = 'middle';
    else if (angle <= 180) textAnchor = 'start';
    else textAnchor = 'end';
  
    return (
      <g>
        {lines.map((line, index) => (
          <text
            key={index}
            x={xPos}
            y={yPos + (index * 12) - ((lines.length - 1) * 6)}
            textAnchor={textAnchor}
            fontSize={window.innerWidth <= 480 ? "8" : "10"}
            fontWeight="500"
            fill={secondaryColor}
            className={styles.radarLabel}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  const maxScore = Math.max(...data.map(item => item.score));
  const domainMax = Math.ceil(maxScore / 10) * 10;

  return (
    <ResponsiveContainer width="100%" height={320}>
      <RadarChart 
        data={data}
        margin={dimensions.margin}
        cx="50%"
        cy="50%"
        startAngle={90}
        endAngle={-270}
        outerRadius={dimensions.outerRadius}
      >
        <PolarGrid 
          stroke={secondaryColor}
          strokeOpacity={0.4}
          gridType="polygon"
        />
        <PolarAngleAxis 
          dataKey="subject"
          tick={<CustomizedAxisTick />}
          tickLine={false}
          cx="50%"
          cy="50%"
          radius={dimensions.outerRadius}
        />
        <PolarRadiusAxis
          angle={90}
          domain={[0, domainMax]}
          tick={false}
          axisLine={false}
          tickCount={6}
        />
        <Radar
          name="Score"
          dataKey="score"
          stroke={secondaryColor}
          fill={secondaryColor}
          fillOpacity={0.2}
          dot
          isAnimationActive={false}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const ResultPage = ({
  quizResults,
  universityData,
  logoUrl,
  professionProfiles = []
}) => {
  const { t, i18n } = useTranslation();
  const secondaryColor = universityData?.design?.secondary_color || '#062555';
  const primaryColor = universityData?.design?.primary_color || '#FAFAFA';
  
  const dynamicStyles = {
    container: {
      backgroundColor: primaryColor,
      '--secondary-color': secondaryColor,
      '--primary-color': primaryColor,
      '--secondary-transparent': `${secondaryColor}1A`,
      '--secondary-light': `${secondaryColor}0D`,
      '--border-color': `${secondaryColor}1A`,
      '--text-color': secondaryColor,
    },
    title: {
      color: secondaryColor,
    },
    score: {
      backgroundColor: secondaryColor,
      color: primaryColor,
      border: `1px solid ${secondaryColor}`,
    },
    button: {
      backgroundColor: secondaryColor,
      color: primaryColor,
      border: `1px solid ${secondaryColor}`,
    }
  };

  const getMatchedProfiles = () => {
    if (!quizResults?.recommended_profiles?.length) return [];
    
    const recommendedIds = quizResults.recommended_profiles.map(profile => profile.id);
    return professionProfiles.filter(profile => recommendedIds.includes(profile.id));
  };

  const generateChartData = () => {
    if (!quizResults?.profile_results || !professionProfiles) return [];
    
    // Create a map of profile IDs to their multilingual names
    const profileNameMap = {};
    professionProfiles.forEach(profile => {
      profileNameMap[profile.id] = profile.name;
    });

    // Use the order directly from profile_results
    return Object.entries(quizResults.profile_results).map(([key, data]) => {
      const profile = profileNameMap[data.id];
      return {
        subject: profile ? profile[i18n.language] || Object.values(profile)[0] : key,
        score: data.score,
        name: key
      };
    });
  };

  const getAllProfessions = () => {
    const matchedProfiles = getMatchedProfiles();
    const allProfessions = [];

    matchedProfiles.forEach(profile => {
      if (Array.isArray(profile.professions)) {
        profile.professions.forEach(profession => {
          if (!allProfessions.some(p => 
            p.name?.[i18n.language] === profession.name?.[i18n.language]
          )) {
            allProfessions.push({
              ...profession,
              sourceProfile: profile.id
            });
          }
        });
      }
    });

    return allProfessions;
  };

  const getAllCourses = () => {
    const matchedProfiles = getMatchedProfiles();
    const allCourses = [];

    matchedProfiles.forEach(profile => {
      if (Array.isArray(profile.courses)) {
        profile.courses.forEach(course => {
          if (!allCourses.some(c => c.link === course.link)) {
            allCourses.push({
              ...course,
              sourceProfile: profile.id
            });
          }
        });
      }
    });

    return allCourses;
  };

  const renderRadarChart = () => {
    const data = generateChartData();
    return (
      <div className={styles.radarChartContainer}>
        <ResponsiveRadarChart data={data} secondaryColor={secondaryColor} />
      </div>
    );
  };

  const renderProfessions = () => {
    const professions = getAllProfessions();
    
    return professions.map((profession, index) => (
      <div key={`${profession.sourceProfile}-${index}`} className={styles.recommendedProfile}>
        <div className={styles.profileHeader}>
          <h4 className={styles.profileName}>
            {profession.name?.[i18n.language] || profession.name?.en}
          </h4>
        </div>
        {profession.description && (
          <div className={styles.professionDescription}>
            {profession.description[i18n.language] || profession.description.en}
          </div>
        )}
      </div>
    ));
  };

  const renderCourses = () => {
    const courses = getAllCourses();
    
    return courses.map((course, index) => (
      <div key={`${course.sourceProfile}-${index}`} className={styles.courseCard}>
        <div className={styles.courseInfo}>
          <span className={styles.courseName}>
            {course.name?.[i18n.language] || course.name?.en || t('common.untitled')}
          </span>
        </div>
        <div className={styles.courseButtonWrapper}>
          <a
            href={course.link}
            className={styles.courseButton}
            style={dynamicStyles.button}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('resultPage.visitCourse')}
          </a>
        </div>
      </div>
    ));
  };

  if (!quizResults?.recommended_profiles?.length) {
    return (
      <div className={styles.resultContainer} style={dynamicStyles.container}>
        <header className={styles.header}>
          {logoUrl && <img src={logoUrl} alt="University logo" className={styles.logoImage} />}
        </header>
        <main className={styles.resultContent}>
          <h1 className={styles.title} style={dynamicStyles.title}>
            {t('resultPage.noResults')}
          </h1>
          <p className={styles.thankYou}>
            {t('resultPage.thankYou', { name: quizResults.name || t('common.untitled') })}
          </p>
        </main>
      </div>
    );
  }

  return (
    <div className={styles.resultContainer} style={dynamicStyles.container}>
      <header className={styles.header}>
        {logoUrl && <img src={logoUrl} alt="University logo" className={styles.logoImage} />}
      </header>
      <main className={styles.resultContent}>
        <div className={styles.heroSection}>
          <h1 className={styles.title} style={dynamicStyles.title}>
            {t('resultPage.results')}
          </h1>
          <p className={styles.thankYou}>
            {t('resultPage.thankYou', { name: quizResults.name || t('common.untitled') })}
          </p>
        </div>

        <div className={styles.mainContentArea}>
          <div className={styles.columnsContainer}>
            <div className={styles.recommendationsWrapper}>
              <h2 className={styles.columnTitle} style={dynamicStyles.title}>
                {t('resultPage.recommendedProfessions')}
              </h2>
              <div className={styles.recommendationsColumn}>
                <div className={styles.scrollableContent}>
                  {renderProfessions()}
                </div>
              </div>
            </div>

            <div className={styles.radarChartWrapper}>
              <h2 className={styles.columnTitle} style={dynamicStyles.title}>
                {t('resultPage.profileMatch')}
              </h2>
              <div className={styles.radarChartColumn}>
                {renderRadarChart()}
              </div>
            </div>
          </div>

          <div className={styles.coursesSection}>
            <h2 className={styles.coursesTitle} style={dynamicStyles.title}>
              {t('resultPage.recommendedCourses')}
            </h2>
            <div className={styles.coursesGrid}>
              {renderCourses()}
            </div>
          </div>
        </div>
      </main>

      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
          {t('common.poweredBy')}
          <img src="/logo.png" className={styles.workxplorerIcon} alt="WorkXplorer logo" />
        </div>
      </footer>
    </div>
  );
};

export default ResultPage;