import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/UniversityQuizAppStyles/StartQuiz.module.css';

const StartQuiz = ({ universityData, logoUrl, onStartQuiz, availableLanguages }) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const secondaryColor = universityData?.design?.secondary_color || '#062555';
  
  // Encode the SVG for use in CSS
  const encodeSvg = (color) => {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="${color}"><path d="M7 10l5 5 5-5z"/></svg>`;
    return encodeURIComponent(svg);
  };

  const languageSelectorStyle = {
    color: secondaryColor,
    borderColor: secondaryColor,
    backgroundImage: `url("data:image/svg+xml,${encodeSvg(secondaryColor)}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 8px center',
    backgroundSize: '24px'
  };

  return (
    <div className={styles.startQuizContainer} style={{ backgroundColor: universityData?.design?.primary_color || '#FAFAFA' }}>
      <header className={styles.header}>
        <img src={logoUrl} alt={`${universityData.universityName} logo`} className={styles.logo} />
        <select 
          value={i18n.language} 
          onChange={handleLanguageChange} 
          className={styles.languageSelector}
          style={languageSelectorStyle}
        >
          {availableLanguages.map((lang) => (
            <option key={lang} value={lang}>{lang.toUpperCase()}</option>
          ))}
        </select>
      </header>
      
      <main className={styles.content}>
        <h1 className={styles.title} style={{ color: universityData.design.secondary_color }}>
          {t('startQuiz.title')}
        </h1>
        
        <button 
          className={styles.startButton}
          onClick={onStartQuiz}
          style={{ 
            backgroundColor: universityData.design.secondary_color,
            color: '#ffffff'
          }}
        >
          {t('startQuiz.startButton')}
        </button>
        
        <p className={styles.disclaimer}>
          {t('startQuiz.disclaimer')}
        </p>
      </main>

      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
          {t('common.poweredBy')}
          <img src="/logo.png" alt="WorkXplorer logo" className={styles.workxplorerIcon} />
        </div>
      </footer>
    </div>
  );
};

export default StartQuiz;