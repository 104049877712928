import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  fetchQuestions, 
  updateQuestion, 
  createQuestion, 
  deleteQuestion, 
  logout, 
  uploadQuestions,
  fetchProfessionProfiles,
  createProfessionProfile,
  updateProfessionProfile,
  deleteProfessionProfile
} from '../../utils/api';
import StatisticsDashboard from './StatisticsDashboard';
import styles from '../../styles/UniversityQuizAppStyles/AdminDashboard.module.css';
import QuestionManagement from './QuestionManagement';
import ProfileManagement from './ProfileManagement';
import LoadingComponent from './LoadingComponent';
import { useTranslation } from 'react-i18next';
import { Plus, Globe, Trash2 } from 'lucide-react';

const AdminDashboard = () => {
  const { t, i18n } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [professionProfiles, setProfessionProfiles] = useState([]); // Initialize as empty array
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedQuizLanguage, setSelectedQuizLanguage] = useState('uz');
  const [activeSection, setActiveSection] = useState('questions');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('statistics');
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [availableLanguages] = useState(['uz', 'ru', 'en']);

  // Initialize new question state
  const [newQuestion, setNewQuestion] = useState({
    question_text: { uz: '', ru: '', en: '' },
    variants: [{
      variant_text: { uz: '', ru: '', en: '' },
      profession_profile_points: {}
    }]
  });

  // Initialize new profile state
  const [newProfile, setNewProfile] = useState({
    name: { uz: '', ru: '', en: '' },
    description: { uz: '', ru: '', en: '' },
    professions: [],
    courses: [],
    admission_info: { uz: '', ru: '', en: '' }
  });

  // Modified selectedProfile calculation
  const selectedProfile = selectedProfileId 
    ? (professionProfiles || []).find(p => p.id === selectedProfileId) 
    : newProfile;

  // Modified selectedQuestion calculation
  const selectedQuestion = selectedQuestionId
    ? (questions || []).find(q => q.id === selectedQuestionId)
    : newQuestion;

  const navigate = useNavigate();

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [questionsResponse, profilesResponse] = await Promise.all([
          fetchQuestions().catch(err => {
            console.error('Error fetching questions:', err);
            return { data: { results: [] } };
          }),
          fetchProfessionProfiles().catch(err => {
            console.error('Error fetching profiles:', err);
            return { data: [] };
          })
        ]);

        // Set questions data
        if (questionsResponse.data && Array.isArray(questionsResponse.data.results)) {
          setQuestions(questionsResponse.data.results);
          if (questionsResponse.data.results.length > 0) {
            setSelectedQuestionId(questionsResponse.data.results[0].id);
          }
        }

        // Set profiles data
        if (profilesResponse.data) {
          setProfessionProfiles(Array.isArray(profilesResponse.data) ? profilesResponse.data : []);
          if (profilesResponse.data.length > 0) {
            setSelectedProfileId(profilesResponse.data[0].id);
          }
        }

      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
        if (err.response?.status === 401) {
          handleLogout();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleGlobalLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setShowLanguageDropdown(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to logout. Please try again.');
    }
  };

  const handleQuestionChange = (field, value, lang = null) => {
    if (selectedQuestionId) {
      setQuestions(prevQuestions => 
        prevQuestions.map(q => {
          if (q.id === selectedQuestionId) {
            if (lang) {
              return {
                ...q,
                [field]: { ...q[field], [lang]: value }
              };
            }
            return { ...q, [field]: value };
          }
          return q;
        })
      );
    } else {
      if (lang) {
        setNewQuestion(prev => ({
          ...prev,
          [field]: { ...prev[field], [lang]: value }
        }));
      } else {
        setNewQuestion(prev => ({ ...prev, [field]: value }));
      }
    }
  };

  const handleVariantChange = (variantIndex, field, value, lang = null, profileId = null) => {
    if (selectedQuestionId) {
      setQuestions(prevQuestions => 
        prevQuestions.map(q => {
          if (q.id === selectedQuestionId) {
            const updatedVariants = [...q.variants];
            if (lang) {
              updatedVariants[variantIndex] = {
                ...updatedVariants[variantIndex],
                [field]: { ...updatedVariants[variantIndex][field], [lang]: value }
              };
            } else if (profileId) {
              updatedVariants[variantIndex] = {
                ...updatedVariants[variantIndex],
                profession_profile_points: {
                  ...updatedVariants[variantIndex].profession_profile_points,
                  [profileId]: parseInt(value) || 0
                }
              };
            } else {
              updatedVariants[variantIndex] = {
                ...updatedVariants[variantIndex],
                [field]: value
              };
            }
            return { ...q, variants: updatedVariants };
          }
          return q;
        })
      );
    } else {
      setNewQuestion(prev => {
        const updatedVariants = [...prev.variants];
        if (lang) {
          updatedVariants[variantIndex] = {
            ...updatedVariants[variantIndex],
            [field]: { ...updatedVariants[variantIndex][field], [lang]: value }
          };
        } else if (profileId) {
          updatedVariants[variantIndex] = {
            ...updatedVariants[variantIndex],
            profession_profile_points: {
              ...updatedVariants[variantIndex].profession_profile_points,
              [profileId]: parseInt(value) || 0
            }
          };
        } else {
          updatedVariants[variantIndex] = {
            ...updatedVariants[variantIndex],
            [field]: value
          };
        }
        return { ...prev, variants: updatedVariants };
      });
    }
  };

  const handleProfileChange = (field, value, lang = null) => {
    if (selectedProfileId && Array.isArray(professionProfiles)) {
      setProfessionProfiles(prevProfiles => 
        prevProfiles.map(p => {
          if (p.id === selectedProfileId) {
            if (lang) {
              return {
                ...p,
                [field]: { ...p[field], [lang]: value }
              };
            }
            return { ...p, [field]: value };
          }
          return p;
        })
      );
    } else {
      if (lang) {
        setNewProfile(prev => ({
          ...prev,
          [field]: { ...prev[field], [lang]: value }
        }));
      } else {
        setNewProfile(prev => ({ ...prev, [field]: value }));
      }
    }
  };

  const handleAddProfession = () => {
    const newProfession = availableLanguages.reduce(
      (acc, lang) => ({ ...acc, [lang]: '' }), 
      {}
    );

    if (selectedProfileId) {
      setProfessionProfiles(prevProfiles =>
        prevProfiles.map(p => {
          if (p.id === selectedProfileId) {
            return { ...p, professions: [...p.professions, newProfession] };
          }
          return p;
        })
      );
    } else {
      setNewProfile(prev => ({
        ...prev,
        professions: [...prev.professions, newProfession]
      }));
    }
  };

  const handleProfessionChange = (index, value, lang) => {
    if (selectedProfileId) {
      setProfessionProfiles(prevProfiles =>
        prevProfiles.map(p => {
          if (p.id === selectedProfileId) {
            const updatedProfessions = [...p.professions];
            updatedProfessions[index] = {
              ...updatedProfessions[index],
              [lang]: value
            };
            return { ...p, professions: updatedProfessions };
          }
          return p;
        })
      );
    } else {
      setNewProfile(prev => {
        const updatedProfessions = [...prev.professions];
        updatedProfessions[index] = {
          ...updatedProfessions[index],
          [lang]: value
        };
        return { ...prev, professions: updatedProfessions };
      });
    }
  };

  // Save handlers
  const handleSaveQuestion = async () => {
    try {
      if (selectedQuestionId) {
        const questionToUpdate = questions.find(q => q.id === selectedQuestionId);
        const response = await updateQuestion(selectedQuestionId, questionToUpdate);
        setQuestions(prevQuestions => 
          prevQuestions.map(q => q.id === response.data.id ? response.data : q)
        );
      } else {
        const response = await createQuestion(newQuestion);
        setQuestions(prev => [...prev, response.data]);
        setSelectedQuestionId(response.data.id);
        setNewQuestion({
          question_text: { uz: '', ru: '', en: '' },
          variants: [{
            variant_text: { uz: '', ru: '', en: '' },
            profession_profile_points: {}
          }]
        });
      }
      alert(t('adminDashboard.alerts.questionSaved'));
    } catch (error) {
      console.error('Error saving question:', error);
      setError(t('adminDashboard.alerts.saveFailed'));
    }
  };

  const handleSaveProfile = async () => {
    try {
      if (selectedProfileId) {
        const profileToUpdate = professionProfiles.find(p => p.id === selectedProfileId);
        const response = await updateProfessionProfile(selectedProfileId, profileToUpdate);
        setProfessionProfiles(prevProfiles => 
          prevProfiles.map(p => p.id === response.data.id ? response.data : p)
        );
      } else {
        const response = await createProfessionProfile(newProfile);
        setProfessionProfiles(prev => [...prev, response.data]);
        setSelectedProfileId(response.data.id);
        setNewProfile({
          name: { uz: '', ru: '', en: '' },
          description: { uz: '', ru: '', en: '' },
          professions: [],
          courses: [],
          admission_info: { uz: '', ru: '', en: '' }
        });
      }
      alert(t('adminDashboard.alerts.profileSaved'));
    } catch (error) {
      console.error('Error saving profile:', error);
      setError(t('adminDashboard.alerts.saveFailed'));
    }
  };

  // Delete handlers
  const handleDeleteQuestion = async () => {
    if (!selectedQuestionId) return;
    
    if (window.confirm(t('adminDashboard.confirms.deleteQuestion'))) {
      try {
        await deleteQuestion(selectedQuestionId);
        setQuestions(prev => prev.filter(q => q.id !== selectedQuestionId));
        setSelectedQuestionId(questions[0]?.id || null);
        alert(t('adminDashboard.alerts.questionDeleted'));
      } catch (error) {
        console.error('Error deleting question:', error);
        setError(t('adminDashboard.alerts.deleteFailed'));
      }
    }
  };

  const handleDeleteProfile = async () => {
    if (!selectedProfileId) return;
    
    if (window.confirm(t('adminDashboard.confirms.deleteProfile'))) {
      try {
        await deleteProfessionProfile(selectedProfileId);
        setProfessionProfiles(prev => prev.filter(p => p.id !== selectedProfileId));
        setSelectedProfileId(professionProfiles[0]?.id || null);
        alert(t('adminDashboard.alerts.profileDeleted'));
      } catch (error) {
        console.error('Error deleting profile:', error);
        setError(t('adminDashboard.alerts.deleteFailed'));
      }
    }
  };

  if (loading) return <LoadingComponent />;
  if (error) return <div className={styles.errorMessage}>{error}</div>;

  return (
    <div className={styles.adminDashboard}>
      <header className={styles.header}>
        <h1>{t('adminDashboard.title')}</h1>
        <nav className={styles.nav}>
          <button 
            onClick={() => setActiveTab('statistics')} 
            className={`${styles.navButton} ${activeTab === 'statistics' ? styles.active : ''}`}
          >
            {t('adminDashboard.nav.statistics')}
          </button>
          <button 
            onClick={() => {
              setActiveTab('management');
              setActiveSection('questions');
            }} 
            className={`${styles.navButton} ${activeTab === 'management' ? styles.active : ''}`}
          >
            {t('adminDashboard.nav.management')}
          </button>
        </nav>
        
        <div className={styles.headerControls}>
          <div className={styles.languageSelector}>
            <button 
              className={styles.languageButton}
              onClick={() => setShowLanguageDropdown(!showLanguageDropdown)}
            >
              <Globe size={20} />
              {i18n.language.toUpperCase()}
            </button>
            {showLanguageDropdown && (
              <div className={styles.languageDropdown}>
                {availableLanguages.map(lang => (
                  <button
                    key={lang}
                    className={`${styles.languageOption} ${i18n.language === lang ? styles.active : ''}`}
                    onClick={() => handleGlobalLanguageChange(lang)}
                  >
                    {lang.toUpperCase()}
                  </button>
                ))}
              </div>
            )}
          </div>
          <button onClick={handleLogout} className={styles.logoutButton}>
            {t('adminDashboard.logout')}
          </button>
        </div>
      </header>

      {activeTab === 'statistics' && <StatisticsDashboard />}

      {activeTab === 'management' && (
  <div className={styles.managementContainer}>
    <div className={styles.managementNav}>
      <button
        onClick={() => setActiveSection('questions')}
        className={`${styles.navButton} ${activeSection === 'questions' ? styles.active : ''}`}
      >
        {t('adminDashboard.nav.questions')}
      </button>
      <button
        onClick={() => setActiveSection('profiles')}
        className={`${styles.navButton} ${activeSection === 'profiles' ? styles.active : ''}`}
      >
        {t('adminDashboard.nav.profiles')}
      </button>
    </div>

    {activeSection === 'questions' && (
      <QuestionManagement
        questions={questions}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
        selectedQuizLanguage={selectedQuizLanguage}
        availableLanguages={availableLanguages}
        setSelectedQuizLanguage={setSelectedQuizLanguage}
        selectedQuestion={selectedQuestion}
        handleQuestionChange={handleQuestionChange}
        handleVariantChange={handleVariantChange}
        professionProfiles={professionProfiles}
        handleSaveQuestion={handleSaveQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
        setQuestions={setQuestions}
        setNewQuestion={setNewQuestion}
        uploadQuestions={uploadQuestions}
        fetchQuestions={fetchQuestions}
      />
    )}

    {activeSection === 'profiles' && (
      <ProfileManagement
        professionProfiles={professionProfiles}
        setProfessionProfiles={setProfessionProfiles}  // Add this line
        selectedProfileId={selectedProfileId}
        setSelectedProfileId={setSelectedProfileId}
        selectedQuizLanguage={selectedQuizLanguage}
        availableLanguages={availableLanguages}
        setSelectedQuizLanguage={setSelectedQuizLanguage}
        selectedProfile={selectedProfile}
        handleProfileChange={handleProfileChange}
        handleProfessionChange={handleProfessionChange}
        handleAddProfession={handleAddProfession}
        handleSaveProfile={handleSaveProfile}
        handleDeleteProfile={handleDeleteProfile}
        setNewProfile={setNewProfile}
      />
    )}
  </div>
)}
    </div>
  );
};

export default AdminDashboard;