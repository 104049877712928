import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/UniversityQuizAppStyles/ManagementStyles.module.css';

const QuestionManagement = ({
  questions,
  selectedQuestionId,
  setSelectedQuestionId,
  selectedQuizLanguage,
  availableLanguages,
  setSelectedQuizLanguage,
  selectedQuestion,
  handleQuestionChange,
  handleVariantChange,
  professionProfiles,
  handleSaveQuestion,
  handleDeleteQuestion,
  setQuestions,
  setNewQuestion,
  uploadQuestions,
  fetchQuestions,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        setLoading(true);
        setError(null);
  
        // Add debugging logs
        console.log('File object:', file);
        console.log('File name:', file.name);
        console.log('File type:', file.type);
        console.log('File size:', file.size);
  
        const formData = new FormData();
        formData.append('file', file);
        
        // Verify FormData contents
        console.log('FormData entries:');
        for (let pair of formData.entries()) {
          console.log(pair[0], pair[1]);
        }
        
        await uploadQuestions(formData);
        const response = await fetchQuestions();
        setQuestions(response.data.results);
        
        alert(t('adminDashboard.alerts.csvUploaded'));
      } catch (error) {
        console.error('Error uploading CSV:', error);
        console.log('Error response:', error.response?.data);  // Add this line
        setError(t('adminDashboard.alerts.csvUploadFailed'));
        if (error.response?.status === 401) {
          alert(t('adminDashboard.alerts.sessionExpired'));
        }
      } finally {
        setLoading(false);
        e.target.value = '';
      }
    }
  };

  const renderVariantPointsInputs = (variant, variantIndex) => {
    return (
      <div className={styles.pointsGrid}>
        {professionProfiles.map(profile => (
          <div key={profile.id} className={styles.pointsInput}>
            <label>
              {profile.name[selectedQuizLanguage] || profile.name.en}
            </label>
            <input
              type="number"
              min="0"
              value={variant.profession_profile_points[profile.id] || 0}
              onChange={(e) => handleVariantChange(
                variantIndex,
                'profession_profile_points',
                e.target.value,
                null,
                profile.id  // Using profile.id instead of hardcoded string
              )}
              className={styles.numberInput}
            />
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <div className={styles.questionManagementContainer}>
      {error && <div className={styles.errorMessage}>{error}</div>}
      
      <div className={styles.sidePanel}>
        <div className={styles.actionButtons}>
          <button 
            className={styles.addButton}
            onClick={() => {
              setSelectedQuestionId(null);
              setNewQuestion({
                question_text: { uz: '', ru: '', en: '' },
                variants: [{
                  variant_text: { uz: '', ru: '', en: '' },
                  profession_profile_points: {}
                }]
              });
            }}
            disabled={loading}
          >
            <Plus size={20} /> {t('adminDashboard.buttons.newQuestion')}
          </button>
          
          <div className={styles.csvUploadContainer}>
            <input
              type="file"
              id="csvUpload"
              accept=".csv"
              className={styles.csvInput}
              onChange={handleFileUpload}
              disabled={loading}
            />
            <label 
              htmlFor="csvUpload" 
              className={`${styles.csvUploadButton} ${loading ? styles.disabled : ''}`}
            >
              {loading 
                ? t('adminDashboard.buttons.uploading')
                : t('adminDashboard.buttons.uploadCsv')
              }
            </label>
          </div>
        </div>

        <div className={styles.questionsList}>
          {questions.map(question => (
            <div
              key={question.id}
              className={`${styles.questionItem} ${selectedQuestionId === question.id ? styles.selected : ''}`}
              onClick={() => !loading && setSelectedQuestionId(question.id)}
            >
              {question.question_text[selectedQuizLanguage] || 
               question.question_text.en || 
               Object.values(question.question_text)[0]}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.languageTabs}>
          {availableLanguages.map(lang => (
            <button
              key={lang}
              className={`${styles.langTab} ${selectedQuizLanguage === lang ? styles.active : ''}`}
              onClick={() => setSelectedQuizLanguage(lang)}
              disabled={loading}
            >
              {lang.toUpperCase()}
            </button>
          ))}
        </div>

        <div className={styles.questionForm}>
          <div className={styles.formGroup}>
            <label>{t('adminDashboard.labels.questionText')}</label>
            <input
              type="text"
              value={selectedQuestion?.question_text[selectedQuizLanguage] || ''}
              onChange={(e) => handleQuestionChange('question_text', e.target.value, selectedQuizLanguage)}
              className={styles.input}
              disabled={loading}
            />
          </div>

          <div className={styles.variantsSection}>
            <h3>{t('adminDashboard.labels.variants')}</h3>
            {selectedQuestion?.variants.map((variant, index) => (
              <div key={index} className={styles.variantItem}>
                <input
                  type="text"
                  value={variant.variant_text[selectedQuizLanguage] || ''}
                  onChange={(e) => handleVariantChange(index, 'variant_text', e.target.value, selectedQuizLanguage)}
                  className={styles.input}
                  disabled={loading}
                />
                
                <div className={styles.pointsGrid}>
                  {professionProfiles.map(profile => (
                    <div key={profile.id} className={styles.pointsInput}>
                      <label>{profile.name[selectedQuizLanguage]}</label>
                      <input
                        type="number"
                        value={variant.profession_profile_points[profile.id] || 0}
                        onChange={(e) => handleVariantChange(index, 'profession_profile_points', e.target.value, null, profile.id)}
                        className={styles.numberInput}
                        disabled={loading}
                      />
                    </div>
                  ))}
                </div>
                {renderVariantPointsInputs(variant, index)}
              </div>
            ))}
            <button
              onClick={() => {
                const newVariant = {
                  variant_text: { uz: '', ru: '', en: '' },
                  profession_profile_points: {}
                };
                if (selectedQuestionId) {
                  setQuestions(prevQuestions => 
                    prevQuestions.map(q => 
                      q.id === selectedQuestionId 
                        ? { ...q, variants: [...q.variants, newVariant] }
                        : q
                    )
                  );
                } else {
                  setNewQuestion(prev => ({
                    ...prev,
                    variants: [...prev.variants, newVariant]
                  }));
                }
              }}
              className={styles.addButton}
              disabled={loading}
            >
              <Plus size={20} /> {t('adminDashboard.buttons.addVariant')}
            </button>
          </div>

          <div className={styles.formActions}>
            <button 
              onClick={handleSaveQuestion} 
              className={styles.saveButton}
              disabled={loading}
            >
              {loading ? t('adminDashboard.buttons.saving') : t('adminDashboard.buttons.save')}
            </button>
            {selectedQuestionId && (
              <button 
                onClick={handleDeleteQuestion} 
                className={styles.deleteButton}
                disabled={loading}
              >
                <Trash2 size={20} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionManagement;