import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styles from '../styles/LandingPage.module.css';

const LandingPage = () => {
  const [activeSection, setActiveSection] = useState('home');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'services', 'contact'];
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className={styles.landingPage}>
      <Helmet>
        <title>WorkXplorer - {t('header.title')}</title>
        <link rel="icon" type="image/png" href="/logo.png" />
      </Helmet>

      <nav className={styles.nav}>
      <div className={`${styles.container} ${styles.navContent}`}>
        <motion.div 
          className={styles.logo}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img src="/logo.png" alt="WrkXplorer Logo" />
        </motion.div>
          <ul>
            {['home', 'about', 'services', 'contact'].map((item) => (
              <motion.li 
                key={item}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <a 
                  href={`#${item}`}
                  className={activeSection === item ? styles.active : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollTo(item);
                  }}
                >
                  {t(`navigation.${item}`)}
                </a>
              </motion.li>
            ))}
          </ul>
          <select value={i18n.language} onChange={handleLanguageChange} className={styles.languageSelector}>
            <option value="uz">O'zbek</option>
            <option value="ru">Русский</option>
            <option value="en">English</option>
          </select>
        </div>
      </nav>

      <motion.header 
        id="home"
        className={styles.header}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className={styles.container}>
          <h1>{t('header.title')}</h1>
          <p>{t('header.subtitle')}</p>
        </div>
      </motion.header>

      <main className={styles.container}>
        <motion.section 
          id="about"
          className={styles.section}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2>{t('about.title')}</h2>
          <p>{t('about.text1')}</p>
          <p>{t('about.text2')}</p>
        </motion.section>

        <motion.section 
          id="services"
          className={styles.section}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2>{t('services.title')}</h2>
          <ul>
            <li>{t('services.service1')}</li>
            <li>{t('services.service2')}</li>
            <li>{t('services.service3')}</li>
            <li>{t('services.service4')}</li>
          </ul>
        </motion.section>

        <motion.section 
          id="contact"
          className={styles.section}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2>{t('contact.title')}</h2>
          <p>{t('contact.text')}</p>
          <button className={styles.ctaButton}>{t('contact.ctaButton')}</button>
        </motion.section>
      </main>

      <footer className={styles.footer}>
        <div className={styles.container}>
          <p>{t('footer')}</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;