import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/UniversityQuizAppStyles/ManagementStyles.module.css';
import { 
  fetchProfessionProfiles, 
  uploadProfessionProfiles 
} from '../../utils/api';

const ProfileManagement = ({
    professionProfiles,
    setProfessionProfiles,
    selectedProfileId,
    setSelectedProfileId,
    selectedQuizLanguage,
    availableLanguages,
    setSelectedQuizLanguage,
    selectedProfile,
    handleProfileChange,
    handleProfessionChange,
    handleAddProfession,
    handleSaveProfile,
    handleDeleteProfile,
    setNewProfile,
  }) => {
    const { t } = useTranslation();
    // Add loading and error states
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const handleFileUpload = async (e) => {
      const file = e.target.files?.[0];
      if (file) {
        try {
          setLoading(true);
          setError(null);
          
          await uploadProfessionProfiles(file);
          
          // Only fetch new data after successful upload
          const response = await fetchProfessionProfiles();
          setProfessionProfiles(response.data);
          
          alert(t('adminDashboard.alerts.profilesCsvUploaded'));
        } catch (error) {
          console.error('Error uploading CSV:', error);
          setError(t('adminDashboard.alerts.csvUploadFailed'));
          if (error.response?.status === 401) {
            alert(t('adminDashboard.alerts.sessionExpired'));
          }
        } finally {
          setLoading(false);
        }
      }
    };
  
    return (
      <div className={styles.profileManagementContainer}>
        {error && <div className={styles.errorMessage}>{error}</div>}
        
        <div className={styles.sidePanel}>
          <button 
            className={styles.addButton}
            onClick={() => {
              setSelectedProfileId(null);
              setNewProfile({
                name: { uz: '', ru: '', en: '' },
                description: { uz: '', ru: '', en: '' },
                professions: [],
                courses: [],
                admission_info: { uz: '', ru: '', en: '' }
              });
            }}
            disabled={loading}
          >
            <Plus size={20} /> {t('adminDashboard.buttons.newProfile')}
          </button>
  
          <div className={styles.csvUploadContainer}>
            <input
              type="file"
              id="profileCsvUpload"
              accept=".csv"
              className={styles.csvInput}
              onChange={handleFileUpload}
              disabled={loading}
            />
            <label 
              htmlFor="profileCsvUpload" 
              className={`${styles.csvUploadButton} ${loading ? styles.disabled : ''}`}
            >
              {loading 
                ? t('adminDashboard.buttons.uploading')
                : t('adminDashboard.buttons.uploadProfilesCsv')
              }
            </label>
          </div>
  
          <div className={styles.profilesList}>
            {professionProfiles.map(profile => (
              <div
                key={profile.id}
                className={`${styles.profileItem} ${selectedProfileId === profile.id ? styles.selected : ''}`}
                onClick={() => !loading && setSelectedProfileId(profile.id)}
              >
                {profile.name[selectedQuizLanguage] || profile.name.en || Object.values(profile.name)[0]}
              </div>
            ))}
          </div>
        </div>
  
        <div className={styles.mainContent}>
          <div className={styles.languageTabs}>
            {availableLanguages.map(lang => (
              <button
                key={lang}
                className={`${styles.langTab} ${selectedQuizLanguage === lang ? styles.active : ''}`}
                onClick={() => setSelectedQuizLanguage(lang)}
                disabled={loading}
              >
                {lang.toUpperCase()}
              </button>
            ))}
          </div>
  
          <div className={styles.profileForm}>
            <div className={styles.formGroup}>
              <label>{t('adminDashboard.labels.profileName')}</label>
              <input
                type="text"
                value={selectedProfile?.name[selectedQuizLanguage] || ''}
                onChange={(e) => handleProfileChange('name', e.target.value, selectedQuizLanguage)}
                className={styles.input}
                disabled={loading}
              />
            </div>
  
            <div className={styles.formGroup}>
              <label>{t('adminDashboard.labels.profileDescription')}</label>
              <textarea
                value={selectedProfile?.description[selectedQuizLanguage] || ''}
                onChange={(e) => handleProfileChange('description', e.target.value, selectedQuizLanguage)}
                className={styles.textarea}
                disabled={loading}
              />
            </div>
  
            <div className={styles.professionsSection}>
              <h3>{t('adminDashboard.labels.professions')}</h3>
              {selectedProfile?.professions.map((profession, index) => (
                <div key={index} className={styles.professionItem}>
                  <input
                    type="text"
                    value={profession[selectedQuizLanguage] || ''}
                    onChange={(e) => handleProfessionChange(index, e.target.value, selectedQuizLanguage)}
                    className={styles.input}
                    disabled={loading}
                  />
                </div>
              ))}
              <button 
                onClick={handleAddProfession} 
                className={styles.addButton}
                disabled={loading}
              >
                <Plus size={20} /> {t('adminDashboard.buttons.addProfession')}
              </button>
            </div>
  
            <div className={styles.formGroup}>
              <label>{t('adminDashboard.labels.admissionInfo')}</label>
              <textarea
                value={selectedProfile?.admission_info[selectedQuizLanguage] || ''}
                onChange={(e) => handleProfileChange('admission_info', e.target.value, selectedQuizLanguage)}
                className={styles.textarea}
                disabled={loading}
              />
            </div>
  
            <div className={styles.formActions}>
              <button 
                onClick={handleSaveProfile} 
                className={styles.saveButton}
                disabled={loading}
              >
                {loading ? t('adminDashboard.buttons.saving') : t('adminDashboard.buttons.save')}
              </button>
              {selectedProfileId && (
                <button 
                  onClick={handleDeleteProfile} 
                  className={styles.deleteButton}
                  disabled={loading}
                >
                  <Trash2 size={20} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ProfileManagement;