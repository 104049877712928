import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'lucide-react';
import { submitQuizResults } from '../../utils/api';
import styles from '../../styles/UniversityQuizAppStyles/UniversityQuiz.module.css';

const UniversityQuiz = ({ 
  universityData, 
  logoUrl, 
  onQuizComplete, 
  startTime,
  professionProfiles 
}) => {
  const { t, i18n } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [profilePoints, setProfilePoints] = useState({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [answerHistory, setAnswerHistory] = useState([]);

  const questions = universityData?.questions || [];

  const handleAnswer = (questionId, variantId, variantText, professionProfilePoints) => {
    setAnswerHistory(prev => [...prev, {
      questionIndex: currentQuestionIndex,
      answers: { ...answers },
      profilePoints: { ...profilePoints }
    }]);

    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: {
        variantId,
        variantText,
        language: i18n.language
      }
    }));

    if (professionProfilePoints && typeof professionProfilePoints === 'object') {
      setProfilePoints(prevPoints => {
        const newPoints = { ...prevPoints };
        Object.entries(professionProfilePoints).forEach(([profile, points]) => {
          if (profile && !isNaN(points)) {
            newPoints[profile] = (newPoints[profile] || 0) + points;
          }
        });
        return newPoints;
      });
    }

    if (currentQuestionIndex === questions.length - 1) {
      setQuizCompleted(true);
    } else {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleGoBack = () => {
    if (answerHistory.length === 0) return;
    
    const previousState = answerHistory[answerHistory.length - 1];
    setCurrentQuestionIndex(previousState.questionIndex);
    setAnswers(previousState.answers);
    setProfilePoints(previousState.profilePoints);
    setAnswerHistory(prev => prev.slice(0, -1));
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^(\+998|998)?(9[012345789]|6[125679]|7[01234569])[0-9]{7}$/;
    return phoneRegex.test(phone);
  };

  const handleUserDetailsSubmit = async (e) => {
    e.preventDefault();
    
    if (!userName || !userPhone) {
      setPhoneError(t('quiz.userInfoForm.formError'));
      return;
    }
  
    if (!validatePhone(userPhone)) {
      setPhoneError(t('quiz.userInfoForm.phoneError'));
      return;
    }
  
    try {
      const formattedAnswers = {};
      const questionIds = [];
      const answerTexts = [];
  
      Object.entries(answers).forEach(([questionId, answerData]) => {
        questionIds.push(questionId);
        answerTexts.push(answerData.variantText);
        formattedAnswers[questionId] = {
          text: answerData.variantText,
          language: answerData.language
        };
      });
  
      const requestData = {
        question_ids: questionIds,
        answer_texts: answerTexts,
        answers_with_language: formattedAnswers,
        name: userName,
        phone: userPhone,
        test_started_at: startTime?.toISOString(),
        profile_points: profilePoints,
        language: i18n.language,
        is_completed: true
      };
  
      console.log('Submitting quiz with:', requestData);
  
      const response = await submitQuizResults(requestData);
      console.log('API Response:', response?.data);
  
      if (response?.data) {
        // Pass the complete response data
        onQuizComplete(response.data);
      } else {
        throw new Error('No response data received');
      }
    } catch (error) {
      console.error('Failed to submit answers:', error);
      setPhoneError(t('quiz.userInfoForm.submitError'));
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  return (
    <div className={styles.quizContainer} style={{ backgroundColor: universityData?.design?.primary_color || '#ffffff' }}>
      <header className={styles.header}>
        {logoUrl && <img src={logoUrl} alt={`${universityData.universityName} logo`} className={styles.logo} />}
        {!quizCompleted && (
          <div className={styles.progressWrapper}>
            <div className={styles.progressBar}>
              <div 
                className={styles.progressFill} 
                style={{ 
                  width: `${progress}%`,
                  backgroundColor: universityData.design?.secondary_color 
                }}
              />
            </div>
          </div>
        )}
        {!quizCompleted && (
          <span className={styles.questionCount} style={{ color: universityData.design?.secondary_color }}>
            {t('quiz.questionCount', { current: currentQuestionIndex + 1, total: totalQuestions })}
          </span>
        )}
      </header>
      
      <main className={styles.quizContent}>
        {!quizCompleted ? (
          <>
            <h2 className={styles.questionText} style={{ color: universityData.design?.secondary_color }}>
              {currentQuestion?.question_text?.[i18n.language]}
            </h2>
            <div className={styles.answerOptions}>
              {currentQuestion?.variants?.map((variant) => (
                <button
                  key={variant.id}
                  onClick={() => handleAnswer(
                    currentQuestion.id, 
                    variant.id, 
                    variant.variant_text?.[i18n.language],
                    variant.profession_profile_points
                  )}
                  className={styles.answerButton}
                  style={{ 
                    color: universityData.design?.secondary_color,
                    borderColor: universityData.design?.secondary_color
                  }}
                >
                  <span>{variant.variant_text?.[i18n.language]}</span>
                </button>
              ))}
            </div>
            {currentQuestionIndex > 0 && (
              <button
                onClick={handleGoBack}
                className={styles.backButton}
                style={{ 
                  color: universityData.design?.secondary_color,
                  borderColor: universityData.design?.secondary_color
                }}
              >
                <ArrowLeft size={20} />
                <span>{t('quiz.backButton')}</span>
              </button>
            )}
          </>
        ) : (
          <form onSubmit={handleUserDetailsSubmit} className={styles.userInfoForm}>
            <h2 style={{ color: universityData.design?.secondary_color }}>
              {t('quiz.userInfoForm.title')}
            </h2>
            <input
              type="text"
              placeholder={t('quiz.userInfoForm.namePlaceholder')}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
              className={styles.input}
            />
            <input
              type="tel"
              placeholder={t('quiz.userInfoForm.phonePlaceholder')}
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
              required
              className={styles.input}
            />
            {phoneError && <p className={styles.errorText}>{phoneError}</p>}
            <button 
              type="submit" 
              className={styles.submitButton}
              style={{
                backgroundColor: universityData.design?.secondary_color,
                color: '#ffffff'
              }}
            >
              {t('quiz.userInfoForm.submitButton')}
            </button>
          </form>
        )}
      </main>

      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
          {t('common.poweredBy')}
          <img src="/logo.png" alt="WorkXplorer logo" className={styles.workxplorerIcon} />
        </div>
      </footer>
    </div>
  );
};

export default UniversityQuiz;