// src/utils/helpers.js

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2 && parts[0] !== 'www') {
    return parts[0];
  } else if (hostname === 'localhost' || hostname === 'wrkxplorer.local') {
    return 'university1'; // Default for local development
  } else {
    return '';
  }
};

export const getApiUrl = () => {
  const protocol = process.env.REACT_APP_PROTOCOL || 'http://';
  const apiUrl = process.env.REACT_APP_API_URL || 'wrkxplorer.local:8000';
  const subdomain = getSubdomain();
  if (subdomain !== '') {
    return `${protocol}${subdomain}.${apiUrl}`;
  } else {
    return `${protocol}${apiUrl}`;
  }
};

export const getAuthUrl = () => {
  const protocol = process.env.REACT_APP_PROTOCOL || 'http://';
  const authUrl = process.env.REACT_APP_AUTH_URL || 'wrkxplorer.local:8000';
  const subdomain = getSubdomain();
  if (subdomain !== '') {
    return `${protocol}${subdomain}.${authUrl}`;
  } else {
    return `${protocol}${authUrl}`;
  }
};

export const getAuthHeader = () => {
  const token = localStorage.getItem('access_token');
  return token ? { 'Authorization': `Bearer ${token}` } : {};
};

export const getMediaUrl = () => {
  const protocol = process.env.REACT_APP_PROTOCOL || 'http://';
  const mediaUrl = process.env.REACT_APP_API_URL || 'wrkxplorer.local:8000';
  const subdomain = getSubdomain();
  if (subdomain !== '') {
    return `${protocol}${subdomain}.${mediaUrl}`;
  } else {
    return `${protocol}${mediaUrl}`;
  }
};
