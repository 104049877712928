// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './global.css';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
      <App />
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();